import React from "react"

import styled from 'styled-components/macro';
import Layout from "../components/layout"
import SEO from "../components/seo"

const OopsDiv = styled.div`
  background-color: rgba(171, 114, 123, .4);
  text-align: center;
  padding: 2rem 4rem;

  h1 {
    font-size: 4rem;
    font-weight: 700;
  }

  p {
    font-size: 3rem;
  }
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <OopsDiv>
      <h1>Oops</h1>
      <p>You just hit a route that doesn&#39;t exist... but don't worry!</p>
    </OopsDiv>
  </Layout>
)

export default NotFoundPage
